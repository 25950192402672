import React, { useEffect, useState } from 'react';
import * as style from './viewportResolution.module.scss';

export const ViewportResolution = () => {
  const [viewportWidth, setViewportWidth] = useState(null);
  const [viewportHeight, setViewportHeight] = useState(null);
  const [deviceType, setDeviceType] = useState('Unknown');

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
      setViewportHeight(window.innerHeight);

      // Basic device detection logic
      const isDesktop = window.innerWidth >= 1024;
      const isTablet = window.innerWidth >= 768 && window.innerWidth < 1024;
      const isMobile = window.innerWidth < 768;

      if (isDesktop) {
        setDeviceType('Desktop');
      } else if (isTablet) {
        setDeviceType('Tablet');
      } else if (isMobile) {
        setDeviceType('Mobile');
      } else {
        setDeviceType('Unknown');
      }
    };

    handleResize(); // Set initial viewport dimensions and device type

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={style.wrapper}>
      <div className='container'>
        <h1 className={style.title}>Browser Viewport Resolution</h1>
        <div className={style.info}>
          <table>
            <thead>
              <tr>
                <th
                  colSpan={2}
                  style={{ backgroundColor: '#13CC00', color: 'white' }}
                >
                  Browser screen resolution in pixels
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Device Type</th>
                <td>{deviceType}</td>
              </tr>
              <tr>
                <th>Viewport Width</th>
                <td>{viewportWidth} pixels</td>
              </tr>
              <tr>
                <th>Viewport Height</th>
                <td>{viewportHeight} pixels</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <p className={style.description}>
            The resolution of your monitor refers to the total number of pixels
            available on your screen. However, the viewport resolution in your
            browser represents the visible area of the web page that you can
            see, excluding parts of the browser interface like toolbars, address
            bars, and scroll bars.
          </p>
          <p className={style.description}>
            The discrepancy between your monitor's resolution and your browser's
            viewport occurs because the browser doesn't use the entire screen;
            it leaves space for its own interface. Additionally, different
            browsers and operating systems may apply scaling or zooming, which
            can further alter the visible area.
          </p>
          <p className={style.description}>
            This difference is normal and expected due to how browsers are
            designed to operate, providing a consistent user experience across
            various devices and resolutions.
          </p>
        </div>
      </div>
    </div>
  );
};
