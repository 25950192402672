import React from 'react';
import { useSiteMetadata } from '../../hooks/useSiteMetadata';
import { useChatBot } from '../../hooks/useChatBot';

function ucFirst(str) {
  if (!str) return str;

  return str[0].toUpperCase() + str.slice(1);
}

export const SeoBlog = ({ title, pathname, children, keywords }) => {
  const {
    title: defaultTitle,
    keywords: defaultKeywords,
    image,
    siteUrl,
    twitterUsername,
  } = useSiteMetadata();
  const clearTitle = title ? title + ' | ' + defaultTitle : defaultTitle;
  const seo = {
    title: ucFirst(clearTitle),
    keywords: keywords || defaultKeywords,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ``}`,
    twitterUsername,
  };

  useChatBot();
  return (
    <>
      <title>Browser Viewport Resolution Checker</title>
      <meta
        name='description'
        content="Check your browser's viewport resolution and dimensions on our web page. Understand the difference between monitor resolution and viewport resolution for better responsive web design."
      ></meta>
      <meta
        name='keywords'
        content='viewport resolution, browser resolution, screen dimensions, responsive web design, monitor resolution'
      ></meta>

      <meta
        property='og:title'
        content='Browser Viewport Resolution Checker'
      ></meta>
      <meta
        property='og:description'
        content="Check your browser's viewport resolution and dimensions on our web page. Understand the difference between monitor resolution and viewport resolution for better responsive web design."
      ></meta>
      <meta property='og:type' content='website'></meta>
      <meta property="og:url" content={seo.url}></meta>

      <meta name='twitter:card' content='summary_large_image'></meta>
      <meta
        name='twitter:title'
        content='Browser Viewport Resolution Checker'
      ></meta>
      <meta
        name='twitter:description'
        content="Check your browser's viewport resolution and dimensions on our web page. Understand the difference between monitor resolution and viewport resolution for better responsive web design."
      ></meta>
      {seo.image && <meta name='twitter:image' content={seo.image} />}

      {children && children}
    </>
  );
};
