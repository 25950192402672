import * as React from 'react';

import { graphql } from 'gatsby';
import { ViewportResolution } from '../../screens/viewportResolution/viewportResolution';
import { SeoBlog } from '../../components/SEO/SEO-Blog';

export const query = graphql`
  query ($language: String, $pageName: [String]) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { in: $pageName } }
    ) {
      edges {
        node {
          ns
          language
          data
        }
      }
    }
  }
`;

const ViewportResolutionPage = () => {
  return <ViewportResolution />;
};

export default ViewportResolutionPage;

export const Head = (data) => {
  return <SeoBlog title={data.pageContext.pageName[0]}/>
};
